<!-- 
    Проекти 
-->

<template>
    
    <!-- Компонент хедеру розділу -->
    <PageHeader :title="title" />

    <!-- 
        Компонент таблиці
        - :dataParams - параметри до кнопки фільтру
        - :objParams - параметри для фільтрації таблиці 
        - :columns - конфігурація стовпців таблиці
        - :rows - масив з даними рядків таблиці
        - :pages - об'єкт із даними про пагінацію таблиці
        - :showpag - параметр, який вказує, чи відображати пагінацію на таблиці
        - :createdform - параметр-прапорець, що дозволяє чи забороняє створення нових записів у таблиці
        - - йде перевірка на права доступу 2000 (режим бога)
        - @create - подія, яка спрацьовує при створенні нового завдання на тестування
        - @open - подія, яка спрацьовує при відкритті картки завдання на тестування
        - @getdata - подія, яка спрацьовує при отриманні даних для таблиці
        - @changelimit - подія, яка спрацьовує при зміні ліміту записів на сторінці
    -->
    <tablecustom 
        :dataParams="dataParams"
        :objParams="objParams"
        @search="searchB" 
        :columns="columns" 
        :rows="rows" 
        :pages="objPages"
        :showpag="true"
        :createdform="this.perms[666]" 
        @create="showCreateBox = true"  
        @open="open" 
        @getdata="getdata"
    />

    <!-- Створення проекту -->
    <createProject 
        v-if="showCreateBox"
        @close="showCreateBox = false;this.getdata()"
    />

</template>


<script>
import PageHeader from "@/components/page-header"; // хедер розділу
import tablecustom from '@/components/globaltable/index'; // компонент таблиці
import createProject from "./createProject.vue";
import { developmentProjects } from '@/API.js';
import { storeS } from '@/store' // глобальне сховище даних
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new developmentProjects();

export default {
    components: {
        PageHeader,
        tablecustom,
        createProject
    },
    data() {
        return {
            title: this.$t('project'),
            showCreateBox: false,
            dataParams: {
                status: true,
                page: "devProjects"
            },
            objPages: {},
            objParams:{
                page: '1',
                pagelimit: '10',
                search: '',
                priority: '',
                status: '',
                type: '',
                workerId: '',
                inJobWorkerId: '',
                problemEnvironment: '',
                pr1: '',
                pr2: '',
                of: 'create_datetime',
                ot: 'desc'
            },
            columns: [
                {
                    name: this.$t('project'),
                    text: "projectName",
                    align: "left",
                    status: true
                },
                {
                    name: this.$t('key'),
                    text: "projectKey",
                    align: "left",
                    status: true
                },
                {
                    name: this.$t('projectLead'),
                    text: "projectManagerName",
                    align: "left",
                    status: true
                },
                {
                    name: this.$t('createDate'),
                    text: "createDatetime",
                    align: "left",
                    status: false
                }
            ],
            rows: [],
            dataProject: {}
        }
    },
    created() {
        this.getdata();
    },
    methods: {
        getdata(page){
            // отримання карток тестування
            this.objParams.page = page != undefined ? page : '1';

            apiServe.getProjects(this.objParams).then(result => {
                if(result.status == 'done') {

                    this.objPages = result.data;
                    this.rows = result.data.items;
                    
                } else {
                    this.$toast.error(this.$t('error') + result.error[0]);
                }
            })
        },
        open(item){
            // Лог для перевірки
            console.log("Дані проєкту:", item);

            this.$router.push({
                name: "pages-open-project-dev", // Назва маршруту
                params: {
                    id: item.projectId, // Передача ID
                    title: item.projectName, // Передача назви проєкту
                },
            });

        },
        switchError(error){
            // відображення помилки

            switch (error) {

                case "project not found":
                    this.$toast.error(this.$t('projectIdnotFound'))
                    break;
            
                default:
                    this.$toast.error(error);

                    var errorText = 'Помилка відкриття картки проекту'; 
                    var statusRequest = result.status;
                    var api = 'getProject';
                    var fileRequest = 'src/views/development/projects/index.vue';
                    var params = id;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));

                    break;
            }
        },
    },
    computed: {
        perms(){
            /*
                Повертає дані прав доступу користувача з глобального стору.
            */
            return storeS.perms
        },
        checks() {
            /*
                Повертає дані з налаштувань юзера з глобального стору.
            */
            return storeS.checks
        },
        user(){
            /*
                Повертає дані за користувача з глобального стору.
            */
            return storeS.userbase
        }
    }
}
</script>